import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import DefaultButton from '../Buttons/DefaultButton';
import { Box, Grid } from '@material-ui/core';
import { string, func } from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import COLORS from '../../constants/colors';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
    flexDirection: 'column',
    '& >p': {
      color: COLORS.DARK_BLUE,
      fontWeight: 400,
      marginBottom: 10,
    },
  },
  input: {
    textAlign: 'center',
    width: 70,
    height: 50,
    border: `2px solid ${COLORS.BLACK}`,
    [theme.breakpoints.between('md', 1060)]: {
      width: 50,
    },
  },
  button: {
    margin: '0px 10px',
    height: 50,
    width: 30,
    [theme.breakpoints.between('md', 1060)]: {
      margin: '0px 5px',
      width: 20,
    },
  },
  options: {
    display: 'flex',
  },
  text: {
    textAlign: 'center',
  },
  upperCase: { textTransform: 'uppercase' },
}));

const AddProductContainer = ({
  label,
  quantityChangeHandler,
  sku,
  itemData,
}) => {
  const classes = useStyle();

  const [quantity, setQuantity] = useState(0);
  const [item, setItem] = useState({
    sku: sku,
    quantity: 0,
  });

  const handleQuantityChange = action => {
    switch (action) {
      case 'add':
        if (quantity < 100) {
          setQuantity(prev => prev + 1);
          setItem(prev => ({
            ...prev,
            quantity: quantity + 1,
          }));
        }
        break;
      case 'sub':
        if (quantity !== 0) {
          setQuantity(prev => prev - 1);
          setItem(prev => ({
            ...prev,
            quantity: quantity - 1,
          }));
        }
        break;
      default:
        return;
    }
  };
  useEffect(() => {
    itemData(item);
  }, [item]);
  useEffect(() => {
    quantityChangeHandler({
      [sku]: quantity,
    });
  }, [quantity]);
  return (
    <Grid item xs={12} md={6} className={classes.container}>
      <p className={classes.upperCase}>{label}</p>
      <Box className={classes.options}>
        <DefaultButton
          onClick={() => handleQuantityChange('sub')}
          className={classes.button}
          style={{ backgroundColor: COLORS.LIGHT_GREY }}
        >
          <RemoveIcon />
        </DefaultButton>
        <InputBase
          classes={{
            root: classes.input,
            input: classes.text,
          }}
          value={quantity}
          readOnly
        />

        <DefaultButton
          onClick={() => handleQuantityChange('add')}
          className={classes.button}
          style={{ backgroundColor: COLORS.DARK_BLUE }}
        >
          <AddIcon />
        </DefaultButton>
      </Box>
    </Grid>
  );
};
AddProductContainer.propTypes = {
  label: string,
  quantityChangeHandler: func.isRequired,
};
AddProductContainer.defaultProps = {
  label: '',
};
export default AddProductContainer;
