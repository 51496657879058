import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Layout from '../components/layout';
import PageContainer from '../components/Containers/PageContainer';
import ProductInfoConatiner from '../components/Product/ProductInfoContainer';
import AddProductContainer from '../components/Containers/AddProductContainer';
import RadioButtonContainer from '../components/Product/RadioButtonContainer';
import { makeStyles } from '@material-ui/core/styles';
import MailingFrom from '../components/Forms/MailingFrom';
import Paper from '@material-ui/core/Paper';
import ProductFormPrivate from '../components/Product/ProductFormPrivate';
import Grid from '@material-ui/core/Grid';
import ProductGallery from '../components/product-gallery-v2';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import ReviewCardSlider from '../components/Sliders/ReviewCardSlider';
import Youtube from '../components/Video/Youtube';
import COLORS from '../constants/colors';
import SEO from '../components/seo';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';
import { shape, func } from 'prop-types';
import { TELEPHONE } from '../constants/company-info';

const useStyles = makeStyles(theme => ({
  rightHalf: {
    '&>*': { marginBottom: 40 },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    [theme.breakpoints.down(1140)]: {
      padding: 10,
    },
    [theme.breakpoints.down('xs')]: {
      margin: 15,
    },
  },
  mailingFormDescription: { margin: '15px 0' },
  grid: {
    width: '100%',
    height: 'auto',
  },
  subtitle: {
    padding: '50px 0 30px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0 10px 0',
    },
  },
  videosContainer: {
    [theme.breakpoints.up('sm')]: {
      flexFlow: 'row',
      justifyContent: 'space-between',
    },
    '&>.MuiGrid-item': {
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexBasis: '100%',
      },
    },
    '&>div>div': {
      width: 700,
      height: 400,
      margin: '0 auto',
      boxShadow: `2px 2px 5px ${COLORS.BLACK}99`,
      [theme.breakpoints.down('lg')]: {
        width: 600,
        height: 350,
      },
      [theme.breakpoints.down('md')]: {
        width: 410,
        height: 230,
      },
      [theme.breakpoints.down('sm')]: {
        width: 260,
        height: 150,
      },
      [theme.breakpoints.down('xs')]: {
        width: 300,
        height: 165,
        margin: '10px auto',
      },
    },
  },
  link: {
    textDecoration: 'none',
    fontWeight: 400,
    '&:hover': {
      color: COLORS.RED,
    },
  },
}));

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PUBLISHABLE_KEY
);

const Product = ({ data, intl }) => {
  const classes = useStyles();
  const { formatMessage } = intl;
  /*TODO:Change data source graphql.*/
  const siteTitle = data.site.siteMetadata.title;
  const reviews = data.allContentfulReview.edges;
  const t = id => formatMessage({ id });

  const [isCompany, setIsCompany] = useState(false);
  const [quantity, setQuantity] = useState({});
  const [
    customerInformation,
    setCustomerInformation,
  ] = useState({});
  const [itemBlackData, setItemBlackData] = useState(0);
  const [itemWhiteData, setItemWhiteData] = useState(0);

  const productImgs = data.allFile.edges.map(
    edge => edge.node.childImageSharp.fluid
  );

  const handleCompanyChange = state => {
    setIsCompany(state);
    //Handle CustomerInformation state
  };

  const handlePurchaseCompany = items => {
    console.log(items);
    setCustomerInformation(items);
  };
  const quantityChangeHandler = items => {
    setQuantity({ ...quantity, ...items });
  };

  const handlePurchasePrivate = async () => {
    const lineItems = [itemBlackData, itemWhiteData];
    const filterLineItems = () => {
      if (lineItems.some(item => item.quantity === 0)) {
        if (itemBlackData['quantity'] !== 0) {
          return [itemBlackData];
        } else if (itemWhiteData['quantity'] !== 0) {
          return [itemWhiteData];
        }
      } else {
        return lineItems;
      }
    };

    const response = await fetch('/api/create-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filterLineItems()),
    }).then(res => res.json());

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    });

    if (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      {/*TODO:Improve SEO and Helmet props values */}
      <SEO
        title="HeadzUp webshop"
        description="Köp Headzup, en produkt för hockeyspelare som vill träna upp sin teknik och puck kontroll."
        keywords={['Headzup']}
      />
      <Helmet title={siteTitle} />
      <PageContainer>
        <Grid container classes={{ root: classes.grid }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ paddingTop: 40 }}
          >
            <ProductInfoConatiner />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.rightHalf}
            style={{ paddingTop: 40 }}
          >
            <Grid item xs={12}>
              <ProductGallery imgFluids={productImgs} />
            </Grid>
            <Grid item xs={12}>
              {/* <Paper className={classes.paper}>
                <RadioButtonContainer
                  isCompany={isCompany}
                  setCompanyState={handleCompanyChange}
                />
                <Grid
                  container
                  className={classes.optionsContainer}
                >
                  <AddProductContainer
                    quantityChangeHandler={
                      quantityChangeHandler
                    }
                    label={t('product.productColors.black')}
                    sku="20060-B"
                    itemData={setItemBlackData}
                  />
                  <AddProductContainer
                    quantityChangeHandler={
                      quantityChangeHandler
                    }
                    label={t('product.productColors.white')}
                    sku="20060-W"
                    itemData={setItemWhiteData}
                  />
                </Grid>
                {isCompany ? (
                  <>
                    <p
                      className={
                        classes.mailingFormDescription
                      }
                    >
                      {formatMessage(
                        {
                          id:
                            'product.mailingFormDescription',
                        },
                        {
                          param1: (
                            <a
                              className={classes.link}
                              href={`tel:${TELEPHONE}`}
                            >
                              {TELEPHONE}
                            </a>
                          ),
                        }
                      )}
                    </p>
                    <MailingFrom
                      submitButtonChild={t(
                        'MailingFrom.submitButtonProduct'
                      )}
                      onSubmit={handlePurchaseCompany}
                    />
                  </>
                ) : (
                  <ProductFormPrivate
                    handleBuyClick={handlePurchasePrivate}
                    quantity={quantity}
                  />
                )}
              </Paper> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Header sub className={classes.subtitle}>
              {t('product.subtitle1')}
            </Header>
            <Grid
              container
              className={classes.videosContainer}
            >
              <Grid item>
                <Youtube videoUrl="https://www.youtube.com/watch?v=SeZlq4QMUAE" />
              </Grid>
              <Grid item>
                <Youtube videoUrl="https://www.youtube.com/watch?v=PRTCAZldO7M" />
              </Grid>
            </Grid>
          </Grid>
          {reviews && (
            <Grid item xs={12}>
              <Header sub className={classes.subtitle}>
                {t('product.subtitle2')}
              </Header>
              {/*TODO:Check if review card slide data source is correct */}
              <ReviewCardSlider
                cardsData={reviews}
                settings={{
                  dots: true,
                }}
              />
            </Grid>
          )}
        </Grid>
      </PageContainer>
    </Layout>
  );
};

Product.propTypes = {
  intl: shape({ formatMessage: func.isRequired })
    .isRequired,
  data: shape().isRequired,
};
export default injectIntl(Product);

export const pageQuery = graphql`
  query ProductPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/src/images/Product-images/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allContentfulReview(
      filter: { visibleIn: { eq: "/webshop" } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          name
          description
          comment {
            comment
          }
        }
      }
    }
  }
`;
