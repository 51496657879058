import React, { useState } from 'react';
import { shape, func } from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/styles';
import { injectIntl } from 'gatsby-plugin-intl';
import COLORS from '../../constants/colors';
import { ButtonBase } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    width: '90%',
  },
  button: {
    marginLeft: 15,
    color: COLORS.RED,
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    ['@media (max-width:600px)']: {
      marginLeft: 15,
    },
  },
  expandedInfo: {
    color: COLORS.WHITE,
    maxWidth: 400,
    overflowWrap: 'break-word',
    marginTop: 20,
    marginLeft: 15,
    whiteSpace: 'pre-line',
    ['@media (max-width:600px)']: {
      marginLeft: 15,
    },
  },
}));

const ProductTabs = ({ intl }) => {
  const classes = useStyles();
  const [isTextVisible, setIsTextVisible] = useState(0);
  const t = id =>
    intl.formatMessage({
      id: id,
    });
  const handleChange = () => {
    setIsTextVisible(!isTextVisible);
  };

  return (
    <div className={classes.root}>
      <ButtonBase
        onClick={handleChange}
        className={classes.button}
      >
        <p>
          {t(
            'ReviewProductDescription.buttonExpand'
          ).toUpperCase()}
        </p>
        {isTextVisible ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </ButtonBase>
      {isTextVisible ? (
        <p className={classes.expandedInfo}>
          {t('ReviewProductDescription.expandedInfo')}
        </p>
      ) : null}
    </div>
  );
};
ProductTabs.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
};
export default injectIntl(ProductTabs);
