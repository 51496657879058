import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'gatsby-plugin-intl';
import DefaultButton from '../../components/Buttons/DefaultButton';
import { func, shape } from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
/*TODO:Change price value & link it with cms */
import { PRICE } from '../../constants/company-info';

const useStyle = makeStyles(theme => ({
  container: {
    margin: '10px 0px 10px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0px 10px 0px',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
    alignItems: 'center',
    borderBottom: '1px solid black ',
    margin: '15px auto',
    width: 'fit-content',
    minWidth: 210,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '15px auto',
    },
  },
  button: {
    maxWidth: 210,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
  },
  sum: {
    fontWeight: 500,
    marginRight: 15,
    textTransform: 'uppercase',
  },
}));

const ProductFormPrivate = ({
  intl,
  handleBuyClick,
  quantity,
}) => {
  const classes = useStyle();
  const { formatMessage } = intl;
  const quantityArray = Object.values(quantity);
  const t = id => formatMessage({ id });

  const sum =
    quantityArray && quantityArray.length
      ? quantityArray.reduce((total, num) => total + num) *
        PRICE
      : 0;

  return (
    <Grid className={classes.container} container>
      <Grid xs={12} md={6} item>
        <Box className={classes.content}>
          <p className={classes.sum}>
            {t('ProductFormPrivate.sum')}
          </p>
          {/*TODO:Change currency if this needs */}
          <p>{sum} SEK</p>
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box className={classes.buttonContainer}>
          <DefaultButton
            onClick={() =>
              handleBuyClick({
                sum: sum,
              })
            }
            classes={{ root: classes.button }}
          >
            {t('ProductFormPrivate.button')}
            {/* TILLFÄLLIGT STÄNGT */}
          </DefaultButton>
        </Box>
      </Grid>
    </Grid>
  );
};

ProductFormPrivate.propTypes = {
  intl: shape({ formatMessage: func.isRequired })
    .isRequired,
  handleBuyClick: func.isRequired,
  quantity: shape(),
};

export default injectIntl(ProductFormPrivate);
