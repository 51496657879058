import React from 'react';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import ReviewProductDescription from './ReviewProductDescription';
import COLORS from '../../constants/colors';
import ProductTab from './ProductTab';
import { PRICE } from '../../constants/company-info';

const useStyle = makeStyles(theme =>
  createStyles({
    container: {
      width: '100%',
      height: 'auto',
      marginBottom: 40,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
      },
    },
    linkContainer: {
      color: COLORS.WHITE,
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 2,
      marginLeft: 5,
      marginTop: 20,
      [theme.breakpoints.down('xs')]: {
        fontSize: 13,
        marginLeft: 0,
      },
    },
    link: {
      border: 'none',
      backgroundColor: 'inherit',
      textDecoration: 'underline',
      color: COLORS.WHITE,
      width: 'fit-content',
      textAlign: 'left',
      cursor: 'pointer',
      outline: 'none',
      '&:hover': {
        color: COLORS.RED,
      },
    },
  })
);

const ProductInfoContainer = () => {
  const classes = useStyle();

  const product = {
    productName: 'F 20',
    price: PRICE,
    information: 'Information',
    specifications: 'Specifikationer',
  };

  return (
    <div className={classes.container}>
      <ReviewProductDescription
        productName={product.productName}
        price={product.price}
        information={product.information}
        specifications={product.specifications}
      />
      <ProductTab />
    </div>
  );
};

export default ProductInfoContainer;
