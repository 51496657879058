import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { makeStyles } from '@material-ui/styles';
import { func, bool, shape } from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';

const useStyles = makeStyles({
  root: { justifyContent: 'center' },
  radio: {
    color: COLORS.RED,
    '&.Mui-checked': {
      color: COLORS.RED,
    },
  },
  radioLabel: {
    fontFamily: FONTS.FAMILY.SECONDARY,
    fontWeight: FONTS.WEIGHT.LIGHT,
    textTransform: 'uppercase',
  },
});

const radioButtons = [
  {
    id: 0,
    value: false,
    label: 'RadioButtonContainer.person',
    defaultChecked: true,
  },
  {
    id: 1,
    value: true,
    label: 'RadioButtonContainer.company',
    defaultChecked: false,
  },
];

const RadioButtonContainer = ({
  intl,
  isCompany,
  setCompanyState,
}) => {
  const classes = useStyles();
  const { formatMessage } = intl;
  const handleChange = event => {
    setCompanyState(
      event.target.value === 'true' ? true : false
    );
  };

  return (
    <RadioGroup
      row
      name="buyer"
      value={isCompany}
      onChange={handleChange}
      className={classes.root}
    >
      {radioButtons.map(item => {
        return (
          <FormControlLabel
            classes={{ root: classes.radioLabel }}
            key={item.id}
            value={item.value}
            control={<Radio className={classes.radio} />}
            label={formatMessage({
              id: item.label,
            })}
          />
        );
      })}
    </RadioGroup>
  );
};

RadioButtonContainer.propTypes = {
  setCompanyState: func.isRequired,
  isCompany: bool.isRequired,
  intl: shape({ formatMessage: func.isRequired })
    .isRequired,
};

export default injectIntl(RadioButtonContainer);
