import React from 'react';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import { shape, func, string, number } from 'prop-types';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { injectIntl } from 'gatsby-plugin-intl';
import F20 from '../../images/Tabloggor/F20_logo.png';

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      width: '90%',
      height: 'auto',
      overflow: 'hidden',
      color: COLORS.WHITE,
    },
    ftwnty: {
      width: 180,
      paddingBottom: 24,
      paddingLeft: 16,
      [theme.breakpoints.down('xs')]: {
        width: 120,
      },
    },
    headerTitle: {
      fontSize: 100,
      fontWeight: FONTS.WEIGHT.BOLD,
      margin: 0,
      textTransform: 'uppercase',
      lineHeight: 1,
      fontFamily: FONTS.FAMILY.SECONDARY,
      [theme.breakpoints.down('xs')]: {
        fontSize: FONTS.SIZE.XXL,
      },
      paddingLeft: 10,
      paddingTop: 10,
    },
    headerPrice: {
      fontSize: FONTS.SIZE.L,
      margin: 0,
      lineHeight: 1,
      marginLeft: 15,
      [theme.breakpoints.down('sm')]: {
        fontSize: FONTS.SIZE.L,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: FONTS.SIZE.M,
      },
      paddingTop: 6,
    },
    description: {
      display: 'flex',
      flexDirection: 'column',
      '& p': { maxWidth: 500 },
    },
    infoContainer: {
      marginBottom: 40,
      marginTop: 20,
      marginLeft: 15,
      maxWidth: 400,
      fontSize: FONTS.SIZE.XS,
      fontFamily: FONTS.FAMILY.PRIMARY,
    },
  })
);

const ReviewProductDescription = ({
  intl,
  productName,
  price,
}) => {
  const { formatMessage, formatNumber } = intl;
  const classes = useStyles();
  const t = id => formatMessage({ id: id });
  return (
    <div className={classes.description}>
      <div className={classes.content}>
        <img src={F20} className={classes.ftwnty}></img>
        {/* <h1 className={classes.headerTitle}>
          {productName}
        </h1> */}
        <h1 className={classes.headerPrice}>
          {formatNumber(price)} SEK
        </h1>
        <div className={classes.infoContainer}>
          <p>{t('ReviewProductDescription.description')}</p>
        </div>
      </div>
    </div>
  );
};

ReviewProductDescription.propTypes = {
  productName: string.isRequired,
  price: number.isRequired,
  intl: shape({
    formatMessage: func.isRequired,
    formatNumber: func.isRequired,
  }).isRequired,
};
export default injectIntl(ReviewProductDescription);
