import React, { useState } from 'react';
import Slider from './Sliders/Slider';
import Img from 'gatsby-image/withIEPolyfill';
import { makeStyles } from '@material-ui/core/styles';
import { array } from 'prop-types';
import COLORS from '../constants/colors';

const useStyles = makeStyles(theme => ({
  activeImage: {
    boxShadow: `2px 2px 5px ${COLORS.BLACK}99`,
    display: 'flex',
    width: '100%',
    height: 'auto',
    marginBottom: 30,
  },
  thumbnailContainer: {
    width: '90%',
    '& .slick-slide': { lineHeight: 0 },
    [theme.breakpoints.up('xl')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiButton-root': {
      backgroundColor: COLORS.WHITE,
      '& .MuiTouchRipple-root>*': {
        color: COLORS.RED,
      },
      '& svg': { color: COLORS.DARK_BLUE },
    },
  },
  thumbnail: {
    boxShadow: `2px 2px 5px ${COLORS.BLACK}99`,
    height: 75,
    display: 'flex',
    alignItems: 'center',
    '& img': { padding: '0 5px' },
    [theme.breakpoints.down('lg')]: {
      height: 70,
    },
    [theme.breakpoints.down('md')]: {
      height: 50,
    },
    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
    [theme.breakpoints.down('xs')]: {
      height: 50,
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ProductGallery = ({ imgFluids }) => {
  const classes = useStyles();
  const [activeImg, setActiveItem] = useState(0);
  const settings = {
    slidesToShow: 4,
    infinite: true,
    lazyLoad: true,
    focusOnSelect: true,
    pauseOnFocus: true,
    autoplay: true,
    afterChange: i => setActiveItem(i),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return imgFluids ? (
    <div className={classes.wrapper}>
      <Img
        fluid={imgFluids[activeImg]}
        draggable={false}
        className={classes.activeImage}
        objectFit="fill"
      />
      {/*TODO:Use random string as key value instead of using index value.*/}
      <div className={classes.thumbnailContainer}>
        <Slider settings={settings}>
          {imgFluids.map((fluid, i) => (
            <Img
              key={i}
              fluid={fluid}
              draggable={false}
              className={classes.thumbnail}
              loading="lazy"
              objectFit="cover"
            />
          ))}
        </Slider>
      </div>
    </div>
  ) : (
    <></>
  );
};

ProductGallery.propTypes = {
  imgFluids: array.isRequired,
};

export default ProductGallery;
